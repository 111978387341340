export default {
  tunnelHostUrl: "https://tarification.aps-benin.com",
  selectNotFoundData: 'Aucun élément trouvé',
  selectLabel: "Choisir",
  selectedLabel: "Sélectionné",
  deselectLabel: "Désélectionner",
  denied: "impossible de modifier cet élément",
  APIKEYKKIAPAY: "ba93132096a211e9a2dd01b2d9052196",
  SANDBOXAPIKEYKKIAPAY: "bb02019096a211e9a7bc7317bf220e85",
}

